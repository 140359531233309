import React, { lazy, Suspense, useState } from 'react';
import { Row, Col, Grid, Link } from 'reaxl';
import { atcBaseUrl } from '../../constants/config';
import { useAnalytics } from 'reaxl-analytics';
import Image from 'next/image';
import { akamaiLoader } from '../../../utils';

const VideoModal = lazy(() => import('./reusableComponents/VideoModal'));
const psxFinancingUrl = atcBaseUrl + '/marketplace/financing';
const psxVSCUrl = atcBaseUrl + '/marketplace/vehicle-service-contracts';
const psxGAPUrl = atcBaseUrl + '/marketplace/gap-insurance';
export default function ShoppingStepsSection() {
  const [showVideo, setShowVideo] = useState(false);
  const { sendClick } = useAnalytics();
  const onFinancingClick = (event: any) => {
    sendClick('psxBLPFinanceClick', event);
  };
  const onVehicleServiceContractClick = (event: any) => {
    sendClick('psxBLPVehicleServiceContractsClick', event);
  };
  const onGapInsuranceClick = (event: any) => {
    sendClick('psxBLPGapInsuranceClick', event);
  };
  return (
    <div className="steps-section">
      <Grid>
        <Row>
          <Col
            md={4}
          >
            <div className="step-item first">
              <div className="step-number">1</div>
              <div>
                <h2>Shop Verified Private Vehicles</h2>
                <p>Chat with verified sellers, schedule test drives, and make offers online.</p>
                <div className="flex-video-container">
                  <Image
                    loader={akamaiLoader}
                    src="how-it-works-video-thumbnail.webp"
                    className="video-thumbnail"
                    alt="How It Works Video"
                    width={72}
                    height={56}
                    onClick={() => {
                      setShowVideo(true);
                    }}
                    loading="lazy"
                  />
                  <Link
                    label="See How It Works"
                    href="#"
                    onClick={() => {
                      setShowVideo(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col
            md={4}
          >
            <div className="step-item second">
              <div className="step-number">2</div>
              <div>
                <h2>Buy Online</h2>
                <p>Pay online with our world-class lenders, your own bank or with cash.</p>
                <p>
                  <Link
                    href={psxFinancingUrl}
                    label="Financing"
                    target="_blank"
                    onClick={(event: any) => onFinancingClick(event)}
                  />
                  ,
                  {' '}
                  <Link
                    href={psxVSCUrl}
                    label="vehicle service contracts"
                    target="_blank"
                    onClick={(event: any) => onVehicleServiceContractClick(event)}
                  />
                  <b>, and</b>
                  {' '}
                  <Link
                    href={psxGAPUrl}
                    label="GAP Insurance"
                    target="_blank"
                    onClick={(event: any) => onGapInsuranceClick(event)}
                  />
                  {' '}
                  <b>available.</b>
                </p>
              </div>
            </div>
          </Col>
          <Col
            md={4}
          >
            <div className="step-item third">
              <div className="step-number">3</div>
              <div>
                <h2>Grab the Keys and Go</h2>
                <p>Pick up the car from the seller or have it delivered to your door.</p>
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
      {showVideo
        && (
          <Suspense fallback={<div />}>
            <VideoModal
              showModal
              onHide={() => setShowVideo(false)}
              src="https://syc-np-content.s3.amazonaws.com/shared/videos/PSX_MEANT+TO+BE_HERO_V4.3.mp4"
            />
          </Suspense>
        )}
    </div>
  );
}
