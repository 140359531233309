import React from 'react';
import { Grid, Row, Button } from 'reaxl';
import { atcBaseUrl } from '../../constants/config';
import { useAnalytics } from 'reaxl-analytics';
import Image from 'next/image';
import { akamaiLoader } from '../../../utils';
import Head from 'next/head';

const shopPrivateSellerUrl = atcBaseUrl + '/cars-for-sale/by-owner';
const sellMyCarUrl = atcBaseUrl + '/sell-my-car';
const viewMyDashboardUrl = atcBaseUrl + '/marketplace/dashboard';
export default function BuyerLandingHero() {
  const { sendClick } = useAnalytics();
  const onShopPrivateSellerClick = (event: any) => {
    sendClick('shopPrivateSellerClick', event);
  };
  const onSellMyCarClick = (event: any) => {
    sendClick('psxSellMyCarClick', event);
  };
  const onViewMyDashboardClick = (event: any) => {
    sendClick('viewMyDashboardClick', event);
  };

  const urlMobile = 'https://www.autotrader.com/psx-static/main/psx-react-web/psx-hero-mobile@2x.webp';
  const urlDesktop = 'https://www.autotrader.com/psx-static/main/psx-react-web/psx-hero-2x.webp';

  return (
    <>
      <Head>
        <link
          rel="preload"
          href={urlMobile}
          as="image"
        />
        <link
          rel="preload"
          href={urlDesktop}
          as="image"
        />
      </Head>
      <div className="buyer-landing-hero">
        <picture>
          <source
            media="(max-width: 766px)"
            srcSet={urlMobile}
          />
          <source
            media="(min-width: 767px)"
            srcSet={urlDesktop}
          />
          <Image
            alt="PSX Hero"
            placeholder="empty"
            priority
            src={urlDesktop}
            fill
            sizes="100vw"
            loader={({ width }) => (width >= 767 ? urlDesktop : urlMobile)}
            style={{
              objectFit: 'cover',
            }}
          />
        </picture>
        <Grid className="hero-box">
          <Row>
            <div className="psx-logo-container">
              <Image
                loader={akamaiLoader}
                src="psx-logo.webp"
                className="psx-logo"
                alt="PSX Logo"
                width={161}
                height={36}
              />
            </div>
          </Row>
          <Row>
            <h1>
              Test drive with sellers.
              <br />
              Buy cars online.
              <br />
              Clean title. No hassle.
            </h1>
          </Row>
          <Row>
            <div className="hero-button-box">
              <Button
                className="hero-button"
                bsStyle="primary"
                href={shopPrivateSellerUrl}
                onClick={onShopPrivateSellerClick}
              >
                Shop Private Seller
              </Button>
              <Button
                className="hero-button"
                bsStyle="secondary"
                href={sellMyCarUrl}
                onClick={onSellMyCarClick}
              >
                Sell My Car
              </Button>
            </div>
          </Row>
          <Row>
            <p>
              Already buying or selling?&nbsp;
              <a
                href={viewMyDashboardUrl}
                onClick={onViewMyDashboardClick}
              >
                View My Dashboard
              </a>
            </p>
          </Row>
        </Grid>
      </div>
    </>
  );
}
