/* eslint no-unused-vars: "off" */
import React from 'react';
import { FooterContentInjector } from 'reaxl-footer';
import { useFeatures } from 'reaxl-features';
import { useAnalytics } from 'reaxl-analytics';
import { useDecision } from '@optimizely/react-sdk';
import dynamic from 'next/dynamic';

const StaticFooter = dynamic(
  () => import('reaxl-footer/es/StaticFooter'),
  { ssr: false },
);
const FooterContainer = () => {
  const {
    disable_ads: [disableAds],
    disable_thirdparty: [disableThirdParty],
    opinion_lab: [isOpinionLabEnabled],
    HIDE_COOKIE_BANNER: [hideCookieBanner],
  } = useFeatures([
    'disable_ads',
    'disable_thirdparty',
    'opinion_lab',
    'HIDE_COOKIE_BANNER',
  ]);
  const reaxlAnalytics = useAnalytics();
  const [enableOneTrustFooter] = useDecision('enableonetrustfooter', { autoUpdate: true });
  const handleFooterClick = (event, data) => (
    reaxlAnalytics.sendClick('footerLinkClick', event, data)
  );
  return (
    <>
      <FooterContentInjector
        renderThirdParty={!disableThirdParty && !disableAds}
      />
      <StaticFooter
        onClick={handleFooterClick}
        enableOneTrustFooter={enableOneTrustFooter?.enabled}
      />
    </>
  );
};
export default FooterContainer;
