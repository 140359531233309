import React from 'react';
import BaseContainer from '../containers/BaseContainer';
import LandingPageContainer from '../containers/LandingPageContainer';
import Head from 'next/head';

export default function Home() {
  return (
    <>
      <Head>
        <title>
          By Owner Cars for Sale Near You from Verified Sellers - Autotrader Private Seller Exchange
        </title>
        <link
          href="https://www.autotrader.com/marketplace"
          rel="canonical"
        />
        <meta
          name="description"
          content="Cars for sale from verified private owners near you. Autotrader's Private Seller Exchange (formerly TRED) is trusted, safe, fast, and secure."
          key="desc"
        />
      </Head>
      <BaseContainer Component={LandingPageContainer} />
    </>
  );
}
