import React from 'react';
import { Row, Col, Grid } from 'reaxl';
import Image from 'next/image';
import { akamaiLoader } from '../../../utils';

const tredHeading = 'TRED Has Joined the Autotrader Family';
const tredSubHeading = 'Enjoy the same great car buying and selling experience with an even bigger selection than before.';
const tredText = 'TRED has joined the Autotrader and Kelley Blue Book family and is now called Private Seller Exchange (or PSX for short). By leveraging all the features that TRED offered, we\'re able to offer car shoppers and sellers an enhanced experience backed by the power of Autotrader.';
export default function BuyerLandingTred() {
  return (
    <div className="tred-section">
      <Grid>
        <Row className="gutter-16">
          <Col
            xs={12}
            sm={9}
            md={8}
            lg={7}
          >
            <h2 className="tred-heading">{tredHeading}</h2>
            <h3 className="tred-subheading">{tredSubHeading}</h3>
            <p className="tred-text">{tredText}</p>
          </Col>
          <Col
            xs={12}
            sm={3}
            md={4}
            lg={3}
            lgOffset={1}
          >
            <Image
              loader={akamaiLoader}
              src="tred-autotrader@2x.webp"
              className="tred-at-img"
              alt="Tred+Autotrader"
              width={235}
              height={175}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
