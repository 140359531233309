import React from 'react';
import { Col, Grid, Row } from 'reaxl';
import { atcBaseUrl } from '../../constants/config';
import { useAnalytics } from 'reaxl-analytics';
import Image from 'next/image';
import { akamaiLoader, atcLoader } from '../../../utils';

export default function BuyerLandingEnsureTrust() {
  const { sendClick } = useAnalytics();
  const identityCheckClick = (event: any) => {
    sendClick('identityCheckClick', event);
  };
  const onFrequentlyAskedLabelClick = (event: any) => {
    sendClick('faqClick', event);
  };
  return (
    <div className="ensure-trust">
      <Grid>
        <h2 className="hidden-sm hidden-md hidden-lg">How Do We Ensure Trust?</h2>
        <Row>
          <Col
            xs={12}
            sm={3}
            md={4}
            lg={3}
            lgOffset={1}
          >
            <Image
              loader={akamaiLoader}
              src="ensure-trust.webp"
              className="trust-img"
              alt="Ensure Trust"
              width={272}
              height={420}
            />
          </Col>
          <Col
            xs={12}
            sm={9}
            md={8}
            lg={7}
          >
            <div className="trust-section">
              <h2 className="hidden-xs">How Do We Ensure Trust?</h2>
              <Row>
                <Col
                  xs={12}
                  sm={6}
                >
                  <div className="trust-item">
                    <div className="trust-item-icon">
                      <Image
                        loader={atcLoader}
                        src="marketplace/v2/images/autotrader/buyer/identity-check.png"
                        className="trust-icon identity"
                        alt="Identity Check"
                        width={64}
                        height={64}
                      />
                    </div>
                    <div className="trust-item-content">
                      <h3>Identity Check</h3>
                      <p>
                        We verify our buyers and sellers by IP addresses, email, phone numbers and scan IDs using
                        {' '}
                        <a
                          href="https://www.stripe.com"
                          onClick={(event: any) => identityCheckClick(event)}
                        >
                          Stripe secure platform
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={6}
                >
                  <div className="trust-item">
                    <div className="trust-item-icon">
                      <Image
                        loader={atcLoader}
                        src="marketplace/v2/images/autotrader/buyer/history-check.png"
                        className="trust-icon"
                        alt="Vehicle History Check"
                        width={64}
                        height={64}
                      />
                    </div>
                    <div className="trust-item-content">
                      <h3>Vehicle History Check</h3>
                      <p>We confirm the car&apos;s ownership and accident history so there are no surprises when you buy.</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  sm={6}
                >
                  <div className="trust-item">
                    <div className="trust-item-icon">
                      <Image
                        loader={atcLoader}
                        src="marketplace/v2/images/autotrader/buyer/secure-payment.png"
                        className="trust-icon"
                        alt="Secure Payment"
                        width={64}
                        height={64}
                      />
                    </div>
                    <div className="trust-item-content">
                      <h3>Secure Payment</h3>
                      <p>We collect payment from the buyer and pay the seller electronically (wire, ACH, credit/debit card).</p>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={6}
                >
                  <div className="trust-item">
                    <div className="trust-item-icon">
                      <Image
                        loader={atcLoader}
                        src="marketplace/v2/images/autotrader/buyer/guaranteed-title.png"
                        className="trust-icon"
                        alt="Guaranteed Title"
                        width={64}
                        height={64}
                      />
                    </div>
                    <div className="trust-item-content">
                      <h3>Guaranteed Title</h3>
                      <p>We pay off the seller&apos;s loan so buyers always receive the title.</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="faq-link">
                <a
                  href={`${atcBaseUrl}/marketplace/faqs`}
                  onClick={(event: any) => onFrequentlyAskedLabelClick(event)}
                >
                  Learn more on our frequently asked questions.
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
